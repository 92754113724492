import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useFirebase } from "contexts/FirebaseContext"
import { useParams } from "react-router-dom"

import { device, contentWidth, offsetMobile } from "constants/variables"
import { getPolicyTitle } from "common/helpers"

import Button from "components/shared/Button"
import Policy from "./components/Policy"
import Error from "components/shared/Error"
import Loader from "components/shared/Loader"
import Title from "components/shared/Title"

import BgImage from "assets/images/bg.png"
import BackButton from "components/shared/BackButton"

const PoliciesPerType = () => {
  const { policyType } = useParams()
  const { getPolicies } = useFirebase()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [policies, setPolicies] = useState(null)

  useEffect(() => {
    const handlePolicies = async () => {
      try {
        const results = await getPolicies()
        setPolicies(
          results.docs
            .map((policy) => ({ ...policy.data(), id: policy.id }))
            .filter((policy) => policy.type === policyType),
        )
      } catch (error) {
        setError("Napaka pri prenosu zavarovalnih polic")
      }
      setLoading(false)
    }
    handlePolicies()
  }, [getPolicies, policyType])

  return (
    <Wrapper>
      <Container>
        <Header>
          <BackButton />
          <ButtonWrapper>
            <Button color="primary" wide>
              Na začetek
            </Button>
          </ButtonWrapper>
        </Header>
        <StyledTitle>{getPolicyTitle(policyType)}</StyledTitle>
        {loading ? (
          <Loader />
        ) : error ? (
          <Error>{error}</Error>
        ) : (
          <>
            <Content>
              {policies.map((policy) => (
                <Policy key={policy.id} policy={policy} />
              ))}
            </Content>
            <Image src={BgImage} />
          </>
        )}
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media ${device.mobile} {
    padding: 0 ${offsetMobile};
  }
`

const Container = styled.div`
  margin: auto;
  max-width: ${contentWidth};
  padding-top: 40px;

  @media ${device.mobile} {
    padding-top: 24px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
  }
`

const StyledTitle = styled(Title)`
  padding-right: 40px;
  margin-top: 8px;

  @media ${device.mobile} {
    padding-right: 0;
    padding-bottom: 40px;
  }
`

const ButtonWrapper = styled.div`
  min-width: 150px;
`

const Content = styled.div`
  margin-top: 56px;
  height: fit-content;
  display: grid;
  grid-gap: 16px 16px;
  grid-template-columns: repeat(4, 25%);

  @media ${device.mobile} {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`

const Image = styled.img`
  width: 100%;
  margin-top: 40px;
`

export default PoliciesPerType
