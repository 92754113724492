import React from "react"
import styled from "styled-components"
import { Switch } from "react-router-dom"
import { useFirebase } from "contexts/FirebaseContext"
import { colors } from "constants/variables"
import Authentication from "components/Authentication"
import Loader from "components/shared/Loader"
import Login from "components/Login"
import Navigation from "components/Navigation"
import Policies from "components/Policies"
import PoliciesPerType from "components/PoliciesPerType"
import PolicyDetails from "components/PolicyDetails"
import PrivateRoute from "components/shared/PrivateRoute"
import PublicRoute from "components/shared/PublicRoute"

const App = () => {
  const { loading } = useFirebase()

  return (
    <Wrapper>
      <Navigation />
      {loading ? (
        <Loader />
      ) : (
        <Switch>
          <PrivateRoute exact path="/" component={Policies} />
          <PrivateRoute exact path="/policies/:policyType" component={PoliciesPerType} />
          <PrivateRoute exact path="/policies/:policyType/:policyId" component={PolicyDetails} />
          <PublicRoute path="/login" component={Login} />
          <PublicRoute path="/auth" component={Authentication} />
          <PublicRoute component={() => <div>Page not found!</div>} />
        </Switch>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
  padding-bottom: 40px;
  background-color: ${colors.background};
  color: ${colors.base};
`

export default App
