import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useFirebase } from "contexts/FirebaseContext"
import { useHistory } from "react-router-dom"

import { device, contentWidth, offsetMobile } from "constants/variables"
import localStorage from "common/localStorage"

import Error from "components/shared/Error"
import Loader from "components/shared/Loader"

const Authentication = () => {
  const { authenticate } = useFirebase()
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")

  useEffect(() => {
    const handleAuthentication = async () => {
      try {
        const email = localStorage.get("email")
        await authenticate(email)
        localStorage.remove("email")
        history.push("/")
      } catch (error) {
        setError("Prišlo je do napake")
        setLoading(false)
      }
    }
    handleAuthentication()
  }, [authenticate, history])

  return (
    <Wrapper>
      <Container>{loading ? <Loader /> : error && <Error>{error}</Error>}</Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media ${device.mobile} {
    padding: 0 ${offsetMobile};
  }
`

const Container = styled.div`
  margin: auto;
  max-width: ${contentWidth};
  padding-top: 40px;

  @media ${device.mobile} {
    padding-top: 24px;
  }
`

export default Authentication
