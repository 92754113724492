import styled from "styled-components"
import { colors, contactEmail } from "constants/variables"
import { getPolicyTypeIcon, getPolicyTitle } from "common/helpers"
import Card from "components/shared/Card"
import Button from "components/shared/Button"
import Typography from "components/shared/Typography"
import Tag from "components/shared/Tag"
import { useHistory } from "react-router-dom"

const PolicyType = ({ policyType, noPolicies = false, singlePolicyWithFilesId }) => {
  const { type, title, count } = policyType

  const history = useHistory()

  return (
    <Card>
      <IconWrapper>{getPolicyTypeIcon(type)}</IconWrapper>
      <TitleWrapper>
        <Typography variant="h3">{title}</Typography>
        <Tag>{count}</Tag>
      </TitleWrapper>
      {!noPolicies ? (
        count ? (
          <Button
            color="primary"
            wide
            onClick={() =>
              history.push(
                singlePolicyWithFilesId
                  ? `/policies/${type}/${singlePolicyWithFilesId}`
                  : `/policies/${type}`,
              )
            }
          >
            {count === 1 ? "Poglej polico" : "Poglej police"}
          </Button>
        ) : (
          <Button
            color="primary"
            wide
            href={`mailto:${contactEmail}?subject=${getPolicyTitle(type)}`}
          >
            Želim informacije
          </Button>
        )
      ) : (
        <Button color="primary" wide>
          V postopku pridobivanja
        </Button>
      )}
    </Card>
  )
}

const IconWrapper = styled.div`
  height: 71px;
  width: 71px;
  background-color: ${colors.secondary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0 32px;
`

const TitleWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
`

export default PolicyType
