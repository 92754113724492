import styled from "styled-components"
import { colors } from "constants/variables"

const Typography = ({ variant, children, ...props }) => {
  let typography
  switch (variant) {
    case "h1":
      typography = <StyledH1 {...props}>{children}</StyledH1>
      break
    case "h2":
      typography = <StyledH2 {...props}>{children}</StyledH2>
      break
    case "h3":
      typography = <StyledH3 {...props}>{children}</StyledH3>
      break
    case "h4":
      typography = <StyledH4 {...props}>{children}</StyledH4>
      break
    case "h5":
      typography = <StyledH5 {...props}>{children}</StyledH5>
      break
    case "h6":
      typography = <StyledH6 {...props}>{children}</StyledH6>
      break
    default:
      typography = <StyledP {...props}>{children}</StyledP>
  }

  return typography
}

const StyledH1 = styled.h1`
  margin: 0;
`

const StyledH2 = styled.h2`
  font-family: "Paytone One";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 105.6%;
  color: ${colors.primary};
  margin: 0;
`

const StyledH3 = styled.h3`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 105.6%;
  margin: 0;
`

const StyledH4 = styled.h4`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: ${colors.grey};
  text-transform: uppercase;
  margin: 8px 0;
`

const StyledH5 = styled.h5``

const StyledH6 = styled.h6``

const StyledP = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 105.6%;
`

export default Typography
