import styled, { css } from "styled-components"

import { colors, radiusSmall } from "constants/variables"

const StyledButton = styled.button`
  width: ${({ wide }) => wide && "100%"};
  padding: 12px;
  border-radius: ${radiusSmall};
  outline: none;
  cursor: pointer;
  border: ${({ color }) => `1px solid ${colors[color]}`};

  ${({ full }) =>
    full
      ? css`
          background-color: ${({ color }) => colors[color]};
          color: ${colors.white};

          &:hover {
            background-color: ${colors.white};
            color: ${({ color }) => colors[color]};
            border: ${({ color }) => `1px solid ${colors[color]}`};
          }
        `
      : css`
          color: ${({ color }) => colors[color]};
          background-color: inherit;
          &:hover {
            background-color: ${({ color }) => colors[color]};
            color: ${colors.white};
          }
        `};
`

const StyledA = styled.a`
  width: ${({ wide }) => wide && "100%"};
  padding: 12px;
  border-radius: ${radiusSmall};
  outline: none;
  cursor: pointer;
  border: ${({ color }) => `1px solid ${colors[color]}`};
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ full }) =>
    full
      ? css`
          background-color: ${({ color }) => colors[color]};
          color: ${colors.white};

          &:hover {
            background-color: ${colors.white};
            color: ${({ color }) => colors[color]};
            border: ${({ color }) => `1px solid ${colors[color]}`};
          }
        `
      : css`
          color: ${({ color }) => colors[color]};
          background-color: inherit;
          &:hover {
            background-color: ${({ color }) => colors[color]};
            color: ${colors.white};
          }
        `};
`

const Button = ({ children, ...props }) => {
  return props.href ? (
    <StyledA {...props}>{children}</StyledA>
  ) : (
    <StyledButton {...props}>{children}</StyledButton>
  )
}

export default Button
