export const colors = {
  primary: "#04A1D8",
  primaryLighten1: "#EDEDFA",
  primaryLighten2: "#5957E5",
  secondary: "#EC5F49",
  secondaryLighten1: "#F9EBE9",
  secondrayLighten2: "#FBA699",
  tertiary: "#D9D9FF",
  white: "#FFFFFF",
  whiteOverlay: "rgba(233, 233, 233, 0.15)",
  black: "#000000",
  grey: "#444444",
  base: "#111111",
  baseLighten1: "#4A4A4A",
  baseLighten2: "#999999",
  baseLighten3: "#F1F1F1",
  background: "#F1F1F1",
}

export const navigationHeight = "88px"
export const contentWidth = "960px"
export const contentWidthSmall = "460px"
export const offsetMobile = "16px"
export const radius = "16px"
export const radiusSmall = "8px"
export const radiusXSmall = "4px"
export const shadow = "0 0 20px rgba(0, 0, 0, 0.1)"

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
}

export const device = {
  mobile: `(max-width: ${size.laptop})`,
  laptop: `(min-width: ${size.laptop})`,
  desktop: `(min-width: ${size.desktop})`,
}

export const policyTypes = [
  {
    type: "car",
    title: "Avtomobilsko",
  },
  {
    type: "home",
    title: "Dom",
  },
  {
    type: "health",
    title: "Zdravje",
  },
  {
    type: "accident",
    title: "Nezgoda",
  },
  {
    type: "life",
    title: "Življenjsko",
  },
  {
    type: "investment",
    title: "Naložba",
  },
  {
    type: "travel",
    title: "Tujina",
  },
  {
    type: "other-type",
    title: "Ostalo",
  },
]

export const contactEmail = "info@inga.si"
