import React, { createContext, useContext, useState, useEffect, useCallback } from "react"
import {
  authInstance,
  dbInstance,
  storageInstance,
  signInWithEmailLink,
  signOut,
  doc,
  getDoc,
  getDocs,
  collection,
  ref,
  getDownloadURL,
} from "lib/firebase-lib"
import localStorage from "common/localStorage"

const FirebaseContext = createContext()

const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  const userId = user ? user.uid : null

  useEffect(() => {
    const unsubscribe = authInstance.onAuthStateChanged((user) => {
      setUser(user)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    setUser(authInstance.currentUser)
  }, [])

  const login = useCallback((email) => {
    localStorage.set("email", email)
  }, [])

  const authenticate = useCallback((email) => {
    return signInWithEmailLink(authInstance, email, window.location.href)
  }, [])

  const logout = useCallback(() => {
    signOut(authInstance)
  }, [])

  const getPolicies = useCallback(() => {
    const policiesRef = collection(dbInstance, `users/${userId}/policies`)
    return getDocs(policiesRef)
  }, [userId])

  const getPolicy = useCallback(
    (policyId) => {
      const policy = doc(dbInstance, `users/${userId}/policies/${policyId}`)
      return getDoc(policy)
    },
    [userId],
  )

  const getStorageUrl = useCallback((path) => {
    return getDownloadURL(ref(storageInstance, path))
  }, [])

  const value = {
    user,
    login,
    logout,
    authenticate,
    getPolicies,
    getPolicy,
    getStorageUrl,
  }
  return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>
}

const useFirebase = () => {
  const context = useContext(FirebaseContext)
  if (!context) {
    throw new Error(`useFirebase must be used within a FirebaseProvider`)
  }
  return context
}

export { FirebaseProvider, useFirebase }
