import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import { useFirebase } from "contexts/FirebaseContext"
import { Document, Page, pdfjs } from "react-pdf"
import { useSnackbar } from "contexts/SnackbarContext"
import { formatDateTime } from "common/helpers"
import Typography from "components/shared/Typography"
import {
  device,
  colors,
  contentWidth,
  offsetMobile,
  shadow,
  radius,
  contactEmail,
} from "constants/variables"
import { getPolicyTitle, getPolicyTypeIcon } from "common/helpers"
import BackButton from "components/shared/BackButton"
import Button from "components/shared/Button"
import Error from "components/shared/Error"
import Loader from "components/shared/Loader"
import LoaderImage from "assets/images/loader-button-primary.svg"

const PolicyDetails = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  const { user, getPolicy, getStorageUrl } = useFirebase()
  const { policyId } = useParams()

  const [loading, setLoading] = useState(true)
  const [loadingPdf, setLoadingPdf] = useState(false)
  const [error, setError] = useState("")
  const [policy, setPolicy] = useState(null)
  const [file, setFile] = useState(null)
  const [pdfUrl, setPdfUrl] = useState("")
  const [numPages, setNumPages] = useState(null)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const handlePolicies = async () => {
      setError("")
      setLoading(true)
      try {
        const results = await getPolicy(policyId)
        const policy = { id: results.id, ...results.data() }
        setPolicy(policy)
        const file = policy.files.filter((policy) => policy.isPolicy === true)[0] || policy.files[0]
        setFile(file)
        const pdfUrl = await getStorageUrl(file.storagePath)
        setPdfUrl(pdfUrl)
      } catch (error) {
        setError("Prišlo je do napake")
      }
      setLoading(false)
    }
    if (user) {
      handlePolicies()
    }
  }, [user, policyId, getPolicy, getStorageUrl])

  const handleChangeFile = async (file) => {
    setFile(file)
    const pdfUrl = await getStorageUrl(file.storagePath)
    setPdfUrl(pdfUrl)
  }

  const plausibleAnalytics = () => {
    if (process.env.REACT_APP_ENV === "production") {
      window.plausible("Download", {
        props: {
          User: user.uid,
          Policy: policyId,
          Company: policy.insurance_company,
          Type: policy.type,
          File: file.name,
        },
      })
    }
  }

  const handleDownload = async (event) => {
    event.preventDefault()
    setLoadingPdf(true)

    try {
      const response = await fetch(pdfUrl, { method: "GET" })
      const buffer = await response.arrayBuffer()
      const blob = new Blob([buffer], { type: "application/pdf" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", file.name)

      if (typeof link.download === "undefined") {
        link.setAttribute("target", "_blank")
      }

      link.click()
      window.URL.revokeObjectURL(url)

      plausibleAnalytics()
    } catch (error) {
      enqueueSnackbar("Napaka pri prenosu police", { variant: "error" })
    }

    setLoadingPdf(false)
  }

  return (
    <Wrapper>
      <Container>
        <BackButton />
        {loading ? (
          <Loader />
        ) : error ? (
          <Error>{error}</Error>
        ) : (
          <>
            <HeaderWrapper>
              <Typography variant="h3">{file.name}</Typography>
              <Typography style={{ fontStyle: "italic", color: colors.grey, margin: 0 }}>
                {formatDateTime(file.created_at)}
              </Typography>
            </HeaderWrapper>

            <Content>
              <DocumentWrapper>
                {pdfUrl ? (
                  <>
                    <Document
                      loading={Loader}
                      file={pdfUrl}
                      onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                    >
                      {[...Array(numPages)].map((_, index) => (
                        <div key={index}>
                          <Page pageNumber={index + 1} />
                          <br />
                        </div>
                      ))}
                    </Document>
                    <Button color="primary" onClick={(event) => handleDownload(event)}>
                      {loadingPdf ? <img src={LoaderImage} alt="..." /> : "Prenesi"}
                    </Button>
                  </>
                ) : (
                  <Loader />
                )}
              </DocumentWrapper>
              <DetailsWrapper>
                <Type>
                  {getPolicyTypeIcon(policy.type, "small")}
                  <Typography style={{ color: colors.white, margin: 0 }}>
                    {getPolicyTitle(policy.type)}
                  </Typography>
                </Type>
                <Details>
                  <Typography variant="h4">ZAVAROVALNICA</Typography>
                  <Typography>{policy.insurance_company}</Typography>
                  {/* <User>
                    <Image src={AdminImage} />
                    <Name>Nemanja</Name>
                  </User> */}
                  {policy.files.filter((file) => file.isPolicy === true).length > 0 && (
                    <div style={{ marginBottom: 16 }}>
                      <Typography variant="h4">ZAVAROVALNA POLICA</Typography>
                      {policy.files
                        .filter((file) => file.isPolicy === true)
                        .map((file) => (
                          <Typography
                            onClick={() => handleChangeFile(file)}
                            style={{
                              textDecoration: "underline",
                              color: colors.primary,
                              cursor: "pointer",
                              margin: "8px 0",
                            }}
                            key={file.created_at}
                          >
                            {file.name}
                          </Typography>
                        ))}
                    </div>
                  )}

                  {policy.files.filter((file) => file.isPolicy === false).length > 0 && (
                    <>
                      <Typography variant="h4">DRUGI DOKUMENTI</Typography>
                      {policy.files
                        .filter((file) => file.isPolicy === false)
                        .map((file) => (
                          <Typography
                            onClick={() => handleChangeFile(file)}
                            style={{
                              textDecoration: "underline",
                              color: colors.primary,
                              cursor: "pointer",
                              margin: "8px 0",
                            }}
                            key={file.created_at}
                          >
                            {file.name}
                          </Typography>
                        ))}
                    </>
                  )}

                  <ButtonWrapper>
                    <Button
                      color="primary"
                      wide
                      full
                      href={`mailto:${contactEmail}?subject=${getPolicyTitle(policy.type)} - ${
                        policy.insurance_company
                      }`}
                    >
                      Več informacij
                    </Button>
                  </ButtonWrapper>
                </Details>
              </DetailsWrapper>
            </Content>
          </>
        )}
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media ${device.mobile} {
    padding: 0 ${offsetMobile};
  }
`

const Container = styled.div`
  margin: auto;
  max-width: ${contentWidth};
  padding-top: 40px;

  @media ${device.mobile} {
    padding-top: 24px;
  }
`

const HeaderWrapper = styled.div`
  width: calc(100% - 267px);
  margin: 32px 0 16px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column-reverse;
  }
`

const DocumentWrapper = styled.div`
  width: calc(100% - 235px);
  padding-right: 32px;

  @media ${device.mobile} {
    width: 100%;
    padding-right: 0;
    padding-top: 32px;
  }
`
const DetailsWrapper = styled.div`
  width: 235px;
  height: 100%;
  background-color: ${colors.white};
  box-shadow: ${shadow};
  border-radius: ${radius};

  @media ${device.mobile} {
    width: 100%;
  }
`

const Type = styled.div`
  background-color: ${colors.secondary};
  display: flex;
  padding: 22px 0px;
  border-radius: ${radius} ${radius} 0 0;
  justify-content: center;
  align-items: center;
  gap: 16px;
`
const Details = styled.div`
  padding: 16px;
`

const ButtonWrapper = styled.div`
  margin-top: 32px;
`

export default PolicyDetails
