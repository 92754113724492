import styled from "styled-components"
import { colors, radiusXSmall } from "constants/variables"

const Tag = ({ children }) => {
  return <StyledSpan>{children}</StyledSpan>
}

const StyledSpan = styled.span`
  padding: 4px 8px;
  border-radius: ${radiusXSmall};
  background-color: ${colors.primaryLighten1};
  color: ${colors.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 105.6%;
`

export default Tag
