import styled from "styled-components"
import { colors, radius, shadow } from "constants/variables"

const Card = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled.div`
  height: 100%;
  padding: 8px;
  background-color: ${colors.white};
  box-shadow: ${shadow};
  border-radius: ${radius};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export default Card
