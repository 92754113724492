import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter as Router } from "react-router-dom"
import { FirebaseProvider } from "contexts/FirebaseContext"
import { SnackbarProvider } from "contexts/SnackbarContext"

if (process.env.REACT_APP_ENV === "production") {
  const script = document.createElement("script")
  script.src = "https://plausible.io/js/plausible.js"
  script.defer = true
  script.setAttribute("data-domain", "inga.si")
  document.head.append(script)

  window.plausible =
    window.plausible ||
    function () {
      ;(window.plausible.q = window.plausible.q || []).push(arguments)
    }
}

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider>
      <FirebaseProvider>
        <Router>
          <App />
        </Router>
      </FirebaseProvider>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
