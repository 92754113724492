import styled from "styled-components"

import { colors } from "constants/variables"

export const Title = styled.div`
  font-family: "Paytone One", sans-serif;
  font-size: 32px;
  color: ${colors.primary};
`

export default Title
