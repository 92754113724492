import styled from "styled-components"

import loader from "assets/images/loader.svg"

const Loader = () => {
  return (
    <Wrapper>
      <img alt="loader" src={loader} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  margin-top: 100px;
`

export default Loader
