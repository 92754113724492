import React, { useState } from "react"
import styled from "styled-components"
import { useFirebase } from "contexts/FirebaseContext"
import { useHistory, Link } from "react-router-dom"
import { useSnackbar } from "contexts/SnackbarContext"

import {
  device,
  colors,
  contentWidth,
  navigationHeight,
  offsetMobile,
  radius,
  radiusSmall,
  shadow,
} from "constants/variables"

import arrow from "assets/images/arrow.svg"
import logo from "assets/images/logo.svg"
import avatar from "assets/images/avatar.svg"

const Navigation = () => {
  const [showLogout, setShowLogout] = useState(false)
  const { user, logout } = useFirebase()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const toggleShowLogout = () => {
    setShowLogout((prevShowLogout) => !prevShowLogout)
  }

  const handleLogout = async () => {
    try {
      await logout()
      history.push("/login")
    } catch (error) {
      enqueueSnackbar("Prišlo je do napake", { variant: "error" })
    }
  }

  return (
    <Wrapper>
      <Content>
        <Link to="/">
          <Logo alt="inga" src={logo} />
        </Link>
        {user && (
          <User onClick={toggleShowLogout}>
            <Email>{user.email}</Email>
            <Image alt="user" src={avatar} />
            <img alt="arrow" src={arrow} />
            <LogoutWrapper showLogout={showLogout} onClick={handleLogout}>
              Odjava
            </LogoutWrapper>
          </User>
        )}
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media ${device.mobile} {
    padding: 0 ${offsetMobile};
  }

  background-color: ${colors.primary};
  color: ${colors.white};
`

const Content = styled.div`
  margin: auto;
  max-width: ${contentWidth};
  height: ${navigationHeight};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Logo = styled.img`
  height: 40px;

  @media ${device.mobile} {
    height: 24px;
  }
`

const User = styled.div`
  position: relative;
  padding: 8px 20px;
  border-radius: ${radius};
  display: flex;
  align-items: center;
  background-color: ${colors.whiteOverlay};
  cursor: pointer;
`

const Email = styled.div`
  margin-right: 20px;
  font-weight: 600;
  color: ${colors.white};

  @media ${device.mobile} {
    display: none;
  }
`

const Image = styled.img`
  margin-right: 10px;
  height: 36px;
  width: 36px;
  object-fit: cover;
`

const LogoutWrapper = styled.div`
  display: ${({ showLogout }) => (showLogout ? "block" : "none")};
  position: absolute;
  right: 0;
  top: 60px;
  padding: 16px 40px;
  border-radius: ${radiusSmall};
  background-color: ${colors.white};
  color: ${colors.base};
  box-shadow: ${shadow};

  &:hover {
    color: ${colors.primary};
  }
`

export default Navigation
