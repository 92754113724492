import styled from "styled-components"
import { useHistory } from "react-router-dom"
import { colors, radius, radiusSmall, contactEmail } from "constants/variables"
import { getPolicyTitle } from "common/helpers"
import Card from "components/shared/Card"
import Button from "components/shared/Button"
import Typography from "components/shared/Typography"
import AdminImage from "assets/images/admin.png"

const Policy = ({ policy }) => {
  const { id, type, insurance_company, files } = policy

  const history = useHistory()

  return (
    <Card>
      <Header>
        <Type>
          <Tag>{getPolicyTitle(type)}</Tag>
        </Type>
        {!files ? (
          <Message>Zavarovalnica nam še ni posredovala podatkov o zavarovanju.</Message>
        ) : (
          <Typography>{insurance_company}</Typography>
        )}
      </Header>
      <Footer>
        <UserWrapper>
          <User>
            <Image src={AdminImage} />
            <Name>Nemanja</Name>
          </User>
        </UserWrapper>
        {files ? (
          <Button color="primary" wide onClick={() => history.push(`/policies/${type}/${id}`)}>
            Podrobnosti
          </Button>
        ) : (
          <Button
            color="secondary"
            wide
            href={`mailto:${contactEmail}?subject=${getPolicyTitle(type)} - ${insurance_company}`}
          >
            Več informacij
          </Button>
        )}
      </Footer>
    </Card>
  )
}

const Header = styled.div`
  width: 100%;
`

const Type = styled.div`
  margin-bottom: 12px;
  height: 98px;
  background-color: ${colors.baseLighten3};
  border-radius: ${radius} ${radius} ${radiusSmall} ${radiusSmall};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Tag = styled.div`
  padding: 8px 20px;
  border-radius: 50px;
  background-color: ${colors.secondary};
  color: ${colors.white};
`
const UserWrapper = styled.div`
  width: 100%;
`

const Message = styled.div`
  font-size: 12px;
`

const Footer = styled.div`
  width: 100%;
`

const User = styled.div`
  margin: 10px 0 12px 0;
  display: flex;
  align-items: center;
`

const Image = styled.img`
  margin-right: 8px;
  background-color: ${colors.baseLighten3};
  width: 35px;
  height: 35px;
  border-radius: 100px;
`

const Name = styled.div`
  color: ${colors.baseLighten1};
`

export default Policy
