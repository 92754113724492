import React, { useCallback } from "react"
import styled from "styled-components"
import FeatherIcon from "feather-icons-react"
import {
  SnackbarProvider as SnackbarProviderInternal,
  useSnackbar as useSnackbarInternal,
} from "notistack"

import { colors } from "constants/variables"

const SnackbarProvider = ({ children, ...props }) => (
  <SnackbarProviderInternal
    maxSnack={3}
    action={SnackbarActions}
    hideIconVariant
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    {...props}
  >
    {children}
  </SnackbarProviderInternal>
)

const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar, ...rest } = useSnackbarInternal()

  return {
    // We use `useCallback` hook to prevent rerender when `enqueueSnackbar` is used as a dependency in `useEffect` hook.
    enqueueSnackbar: useCallback(
      (message, options) =>
        enqueueSnackbar(message, {
          // eslint-disable-next-line react/display-name
          action: (key) => <SnackbarActions id={key} closeSnackbar={closeSnackbar} />,
          autoHideDuration: 5000,
          // We prevent the duplication of snackbars with same messages.
          preventDuplicate: true,
          ...options,
        }),
      [enqueueSnackbar, closeSnackbar],
    ),
    closeSnackbar,
    ...rest,
  }
}

const SnackbarActions = ({ id, closeSnackbar }) => (
  <StyledFeatherIcon icon="x" onClick={() => closeSnackbar(id)} />
)

const StyledFeatherIcon = styled(FeatherIcon)`
  height: 16px;
  margin-right: 8px;
  color: white;
  transition: color 0.1s linear;
  cursor: pointer;
  &:hover {
    color: ${colors.secondrayLighten2};
  }
`

export { SnackbarProvider, useSnackbar }
