import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { colors, radiusSmall, device } from "constants/variables"
import { BackIcon } from "assets/icons"

const BackButton = () => {
  const history = useHistory()

  return (
    <StyledButton onClick={() => history.goBack()}>
      <BackIcon />
      Nazaj
    </StyledButton>
  )
}

const StyledButton = styled.button`
  background-color: ${colors.white};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: ${radiusSmall};
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 105.6%;
  color: ${colors.primary};
  display: flex;
  align-items: center;
  gap: 8px;
  height: fit-content;
  padding: 8px;
  cursor: pointer;

  @media ${device.mobile} {
    width: 80px;
    margin-bottom: 8px;
  }
`

export default BackButton
