import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useFirebase } from "contexts/FirebaseContext"
import { useHistory } from "react-router-dom"

import {
  device,
  colors,
  contentWidthSmall,
  offsetMobile,
  radius,
  shadow,
} from "constants/variables"

import Button from "components/shared/Button"
import Input from "components/shared/Input"
import Title from "components/shared/Title"
import { useSnackbar } from "contexts/SnackbarContext"
import localStorage from "common/localStorage"

import mailImage from "assets/images/mail.png"
import LoaderImage from "assets/images/loader-button-white.svg"

const Login = () => {
  const { user } = useFirebase()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (user) {
      history.push("/")
    }
  }, [user, history])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, redirect_url: `${window.location.origin}/auth` }),
    }

    const sendEmail = async () => {
      setLoading(true)
      try {
        const response = await fetch(
          `${process.env.REACT_APP_AUTH_URL}/sendAuthLink`,
          requestOptions,
        )
        const data = await response.json()
        const success = response && data.status === "success"
        if (success) {
          localStorage.set("email", email)
          setSubmitted(true)
        } else {
          enqueueSnackbar("Prišlo je do napake", { variant: "error" })
        }
      } catch {
        enqueueSnackbar("Prišlo je do napake", { variant: "error" })
      }
      setLoading(false)
    }

    sendEmail()
  }

  return (
    <Wrapper>
      <Container>
        <Title>{submitted ? "Email poslan" : "Pozdravljeni!"}</Title>
        <Box>
          {submitted ? (
            <Submitted email={email} />
          ) : (
            <>
              <MailImage alt="mail" src={mailImage} />
              <Input
                type="email"
                placeholder="Vaš email naslov"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <StyledButton
                color="secondary"
                full
                wide
                disabled={loading}
                type="submit"
                onClick={(event) => handleSubmit(event)}
              >
                {loading ? <img src={LoaderImage} alt="..." /> : "Potrdi"}
              </StyledButton>
            </>
          )}
        </Box>
      </Container>
    </Wrapper>
  )
}

const Submitted = ({ email }) => (
  <div>
    <MailImage alt="mail" src={mailImage} />
    <div>Povezava za vpis je poslana na {email}.</div>
  </div>
)

const Wrapper = styled.div`
  @media ${device.mobile} {
    padding: 0 ${offsetMobile};
  }
`

const Container = styled.div`
  margin: auto;
  max-width: ${contentWidthSmall};
  padding-top: 100px;
  text-align: center;

  @media ${device.mobile} {
    padding-top: 40px;
  }
`

const Box = styled.form`
  margin-top: 24px;
  padding: 48px 40px 24px 40px;
  background-color: ${colors.white};
  box-shadow: ${shadow};
  border-radius: ${radius};
`

const MailImage = styled.img`
  margin: 0 auto 48px auto;
  height: 130px;
  width: 130px;
`

const StyledButton = styled(Button)`
  margin-top: 12px;
`

export default Login
