import moment from "moment"
import {
  CarIcon,
  HomeIcon,
  HealthIcon,
  AccidentIcon,
  LifeIcon,
  InvestmentIcon,
  TravelIcon,
  OtherTypeIcon,
  CarSmallIcon,
  HomeSmallIcon,
  HealthSmallIcon,
  AccidentSmallIcon,
  LifeSmallIcon,
  InvestmentSmallIcon,
  TravelSmallIcon,
  OtherTypeSmallIcon,
} from "../assets/icons"
import { policyTypes } from "constants/variables"

export const formatDateTime = (date) => {
  return moment(date).format("D. M. YYYY HH:mm")
}

export const getPolicyTypeIcon = (type, size = "large") => {
  if (size === "large") {
    switch (type) {
      case "car":
        return <CarIcon />
      case "home":
        return <HomeIcon />
      case "health":
        return <HealthIcon />
      case "accident":
        return <AccidentIcon />
      case "life":
        return <LifeIcon />
      case "investment":
        return <InvestmentIcon />
      case "travel":
        return <TravelIcon />
      case "other-type":
        return <OtherTypeIcon />
      default:
        return <OtherTypeIcon />
    }
  }

  if (size === "small") {
    switch (type) {
      case "car":
        return <CarSmallIcon />
      case "home":
        return <HomeSmallIcon />
      case "health":
        return <HealthSmallIcon />
      case "accident":
        return <AccidentSmallIcon />
      case "life":
        return <LifeSmallIcon />
      case "investment":
        return <InvestmentSmallIcon />
      case "travel":
        return <TravelSmallIcon />
      case "other-type":
        return <OtherTypeSmallIcon />
      default:
        return <OtherTypeSmallIcon />
    }
  }
}

export const getPolicyTitle = (type) =>
  policyTypes.find((policyType) => policyType.type === type).title
