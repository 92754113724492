import styled from "styled-components"

import { colors, radiusSmall } from "constants/variables"

export const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid ${colors.baseLighten2};
  border-radius: ${radiusSmall};
  outline: none;
  text-align: center;

  &:focus {
    border-color: ${colors.secondary};
  }

  ::placeholder {
    color: ${colors.baseLighten2};
  }
`

export default Input
