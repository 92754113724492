import styled from "styled-components"

import errorImage from "assets/images/error.png"

const Error = ({ children }) => {
  return (
    <Wrapper>
      <Image alt="error" src={errorImage} />
      <Message>{children}</Message>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  margin-top: 40px;
`

const Image = styled.img`
  width: 100px;
`

const Message = styled.div`
  text-align: center;
  margin-top: 32px;
  font-size: 20px;
  font-weight: 600;
`

export default Error
